<div class="container">
    <div class="form-container">
      <div class="confirmation-header">
        <h2 class="title" mat-dialog-title>{{ dialogTitle }}</h2>
        <div class="sub-title" mat-dialog-content>{{ confirmationMessage }}</div>
        <div class="form-actions" mat-dialog-actions>
          <app-e-button
            [textContent]="textConstants.CLOSE_BUTTON"
            mat-dialog-close
            tabindex="0"
            (click)="closeDialog()"
          />
          <app-e-button
            [mat-dialog-close]="true"
            [textContent]="confirmBtnMessage"
            btnClass="cancel-button"
            tabindex="0"
            (click)="confirmDialog()"
            (keydown.enter)="confirmDialog()"
          />
        </div>
      </div>
    </div>
  </div>
  