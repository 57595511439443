import { Injectable } from '@angular/core';
import * as RouteConstants from '../constants/route.constants';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { SectionEditRequestModel } from '../submodules/admin/models/content-manager/sections/SectionEditRequestModel';
import { SectionRequestModel } from '../submodules/admin/models/content-manager/sections/SectionRequestModel';
import { Observable } from 'rxjs';
import { SectionOverviewListingModel } from '../models/SectionOverviewListingModel';

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  private routeConstants = RouteConstants.SECTIONS_URLS;

  constructor(private api: ApiService) {}

  getAllSections(moduleId: string) {
    const params = new HttpParams().set('moduleId', moduleId);

    return this.api.get(this.routeConstants.GET_ALL_SECTIONS, params);
  }

  getSectionOverview(id: string): Observable<SectionOverviewListingModel[]> {
    const params = new HttpParams().set('id', id);

    return this.api.get(this.routeConstants.GET_SECTION_OVERVIEW, params);
  }

  getSectionById(id: string) {
    const params = new HttpParams().set('id', id);

    return this.api.get(this.routeConstants.GET_SECTION_BY_ID, params);
  }

  createNewSection(request: SectionRequestModel) {
    return this.api.post(this.routeConstants.CREATE_SECTION, request);
  }

  updateExistingSection(request: SectionEditRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_SECTION, request);
  }

  deleteExistingSection(id: string, courseId: string) {
    let params = new HttpParams();
    params = params.set('id', id);
    params = params.set('courseId', courseId);

    return this.api.delete(this.routeConstants.DELETE_SECTION, params);
  }
}
