import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { SectionsService } from '../../../../services/sections.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-e-sections-overview',
  templateUrl: './e-sections-overview.component.html',
  styleUrl: './e-sections-overview.component.scss',
})
export class ESectionsOverviewComponent implements OnInit {
  @Input() data!: any;
  @Input() videoThumbnailUrl: string = '';
  @Input() isFirstSection: boolean = false;
  @Input() isEndUser: boolean = false;
  isDropdownOpen: boolean = false;
  isLocked: boolean = true;
  sectionParts!: any;
  @Output() selectedContent = new EventEmitter<any>();
  isHovered: boolean = false;
  iconSrc: string = 'assets/images/sub_dropdown_icon.svg';
  hoverIconSrc: string = 'assets/images/sub_dropdown_icon_active.svg';

  constructor(private sectionService: SectionsService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    if (this.isFirstSection == true) {
      this.isDropdownOpen = true;
      this.getSectionParts();
    } else {
      this.isDropdownOpen = false;
    }
  }

  getSectionParts() {
    this.sectionService.getSectionOverview(this.data.id).subscribe((info) => {
      this.sectionParts = info;
    });
  }

  openSelectedContent(contentId: number) {
    this.selectedContent.emit(contentId);
  }

  toggleDropdown() {
    this.isLocked = true;
    this.isDropdownOpen = !this.isDropdownOpen;

    if (this.isDropdownOpen == true) {
      this.getSectionParts();
    }
  }
}
