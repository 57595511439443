<div class="e-tabs-component">
  @for (item of options; track $index) {
  <div
    class="tabs-container"
    [ngClass]="{
      'active-select-purple': dropClass === 'toggle-select-purple' && item === selectedTab,
      'active-select-pink': dropClass === 'toggle-select-pink' && item.id === selectedTab?.id,
      'toggle-select-purple': dropClass === 'toggle-select-purple',
      'toggle-select-pink': dropClass === 'toggle-select-pink',
      'active-select-purple-timestamp': isTimestampInAllSameTimeStamps(item),
      'active-selected-purple-timestamp': item.timestamp ? item === selectedTab : null,

    }"
    (click)="handleSelectedName(item)"
  >
    <div
      class="tab-content"
      [attr.draggable]="isDragable"
      (dragstart)="dragStart(getIndex(item, options) - 1)"
      (dragover)="dragOver($event)"
      (drop)="drop(getIndex(item, options) - 1)"
    >
      @if(imageUrl) {
      <img [src]="imageUrl" [alt]="item.name" />
      } @if (isTimeStamp) {
      <div class="name-holder">
        <div class="item-name">{{ item.name }}</div>
        <div class="item-timestamp">{{ item.timestamp }}</div>
      </div>
      } @else {
      <div class="tab-number-holder">
        <div class="item-name">{{ (numberTab ? getIndex(item, options) + '. ' : '') + item.name }}</div>
      </div>
      }
    </div>
    @if (isDelete) {
    <div class="delete-tab">
      <app-e-icon-button iconSrc="assets/images/delete_actions.svg" [disableHover]="true" (iconClick)="onRemoveSectionClick(item)" />
    </div>
    }
  </div>
  }
</div>
