import { Injectable } from '@angular/core';
import * as RouteConstants from '../constants/route.constants';
import { ApiService } from './api.service';
import { QuestionStatementRequestModel } from '../submodules/admin/models/content-manager/questions/QuestionStatementRequestModel';
import { HttpParams } from '@angular/common/http';
import { CreateVideoQuestionRequestModel } from '../submodules/admin/models/content-manager/questions/CreateVideoQuestionRequestModel';
import { FillInTheBlanksStatementRequestModel } from '../submodules/admin/models/content-manager/questions/FillInTheBlanksStatementRequestModel';
import { NestedVideoQuestionRequestModel } from '../submodules/admin/models/content-manager/questions/NestedVideoQuestionRequestModel';
import { NestedQuestionsOrderRequestModel } from '../submodules/admin/models/content-manager/questions/NestedQuestionsOrderRequestModel';
import { TimestampRequestModel } from '../submodules/admin/models/content-manager/questions/TimestampRequestModel';
import { QuestionOrderRequestModel } from '../submodules/admin/models/content-manager/questions/QuestionOrderRequestModel';
import { CreateKnowledgeTestQuestionRequestModel } from '../submodules/admin/models/content-manager/questions/CreateKnowledgeTestQuestionRequestModel';
import { NestedKnowledgeTestQuestionRequestModel } from '../submodules/admin/models/content-manager/questions/NestedKnowledgeTestQuestionRequestModel';
import { WrongAnswerExplanationRequestModel } from '../submodules/admin/models/content-manager/questions/WrongAnswerExplanationRequestModel';
import { CorrectAnswerExplanationRequestModel } from '../submodules/admin/models/content-manager/questions/CorrectAnswerExplanationRequestModel';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  private routeConstants = RouteConstants.QUESTIONS_URLS;

  constructor(private api: ApiService) {}

  getAllVideoQuestions(videoId: string) {
    const params = new HttpParams().set('videoId', videoId);

    return this.api.get(this.routeConstants.GET_ALL_VIDEO_QUESTION, params);
  }

  getAllDuplicateTimeStampQuestions(videoId: string, timestamp: number) {
    const params = new HttpParams().set('videoId', videoId).set('timestamp', timestamp.toString());

    return this.api.get(this.routeConstants.GET_ALL_DUPLICATE_TIMESTAMP_QUESTION, params);
  }

  getAllKnowledgeTestQuestions(knowledgeTestId: string) {
    const params = new HttpParams().set('knowledgeTestId', knowledgeTestId);

    return this.api.get(this.routeConstants.GET_ALL_KNOWLEDGE_TEST_QUESTION, params);
  }

  getAllNestedQuestions(parentQuestionId: string) {
    const params = new HttpParams().set('parentQuestionId', parentQuestionId);

    return this.api.get(this.routeConstants.GET_ALL_NESTED_QUESTION, params);
  }

  getQuestionById(id: string) {
    const params = new HttpParams().set('id', id);

    return this.api.get(this.routeConstants.GET_BY_ID_QUESTION, params);
  }

  createVideoQuestion(request: CreateVideoQuestionRequestModel) {
    return this.api.post(this.routeConstants.CREATE_VIDEO_QUESTION, request);
  }

  createKnowledgeTestQuestion(request: CreateKnowledgeTestQuestionRequestModel) {
    return this.api.post(this.routeConstants.CREATE_KNOWLEDGE_TEST_QUESTION, request);
  }

  createVideoNestedQuestion(request: NestedVideoQuestionRequestModel) {
    return this.api.post(this.routeConstants.CREATE_VIDEO_NESTED_QUESTION, request);
  }

  createKnowledgeTestNestedQuestion(request: NestedKnowledgeTestQuestionRequestModel) {
    return this.api.post(this.routeConstants.CREATE_KNOWLEDGE_TEST_NESTED_QUESTION, request);
  }

  updateStatement(request: QuestionStatementRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_STATEMENT, request);
  }

  updateWrongAnswerExplanation(request: WrongAnswerExplanationRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_WRONG_ANSWER_EXPLANATION, request);
  }

  updateCorrectAnswerExplanation(request: CorrectAnswerExplanationRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_CORRECT_ANSWER_EXPLANATION, request);
  }

  uploadImage(request: FormData) {
    return this.api.put(this.routeConstants.UPLOAD_IMAGE, request);
  }

  removeImage(id: string, courseId: string) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('courseId', courseId);

    return this.api.delete(this.routeConstants.REMOVE_IMAGE, params);
  }

  updateFillInTheBlanksQuestion(request: FillInTheBlanksStatementRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_FILL_IN_THE_BLANKS_STATEMENT, request);
  }

  updateTimestamp(request: TimestampRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_TIMESTAMP, request);
  }

  updatePrecheckVideoOrder(request: QuestionOrderRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_PRECHECK_VIDEO_ORDER, request);
  }

  updateStandartVideoOrder(request: QuestionOrderRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_STANDART_VIDEO_ORDER, request);
  }

  updateKnowledgeTestOrder(request: QuestionOrderRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_KNOWLEDGE_TEST_ORDER, request);
  }

  updateNestedOrder(request: NestedQuestionsOrderRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_NESTED_ORDER, request);
  }

  removeNestedQuestion(id: string, parentId: string, courseId: string) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('parentId', parentId);
    params = params.append('courseId', courseId);

    return this.api.delete(this.routeConstants.REMOVE_NESTED_QUESTION, params);
  }

  removeVideoQuestion(id: string, courseId: string) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('courseId', courseId);

    return this.api.delete(this.routeConstants.REMOVE_VIDEO_QUESTION, params);
  }

  removeKnowledgeTestQuestion(id: string, courseId: string) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('courseId', courseId);

    return this.api.delete(this.routeConstants.REMOVE_KNOWLEDGE_TEST_QUESTION, params);
  }
}
