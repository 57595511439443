import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EInputComponent } from '../e-input/e-input.component';

@Component({
  selector: 'app-e-upload-video',
  templateUrl: './e-upload-video.component.html',
  styleUrl: './e-upload-video.component.scss',
})
export class EUploadVideoComponent {
  @Output() fileSelected = new EventEmitter<File>();
  @Input() inputClass: string = 'upload';
  @Input() imageInputClass: string = '';
  @Input() imageSrc: string = 'assets/images/upload_video.svg';
  @Input({ required: true }) allowedExtensions: string[] = [];
  @Input() uploadText = 'Upload an image/video';
  @Input() sizeInfoText = '';
  @Input() sizeInfoText2 = '';
  @Input() sizeInfoText3 = '';
  @Input() uploadBytesThreshold: number = 262144000;
  @ViewChild(EInputComponent) nestedInput!: EInputComponent;
  @Input() isValid = true;
  selectedFile: File | null = null;
  @Input() errorMessage: string = '';

  handleFileChange(file: File): void {
    this.selectedFile = file;
    this.isInvalidFileExtension();

    if (this.errorMessage === '') {
      this.isFileTooLarge();
    }

    if (this.isValid) {
      this.fileSelected.emit(this.selectedFile);
    }
  }

  isInvalidFileExtension(): void {
    this.isValid = true;
    this.errorMessage = '';

    const splitFileName = this.selectedFile?.name.split('.');
    const extension = splitFileName ? splitFileName[splitFileName.length - 1] : '';

    if (extension === '' || !this.allowedExtensions.includes(extension)) {
      this.isValid = false;
      this.errorMessage = 'Incorrect format of the file.';
    }
  }

  isFileTooLarge(): void {
    if (this.selectedFile && this.selectedFile.size > this.uploadBytesThreshold) {
      this.isValid = false;

      this.errorMessage = 'File is larger than the given threshold.';
    }
  }
}
