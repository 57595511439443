import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import * as TextConstants from '../../constants/text.constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IErrorUrlParams } from '../../models/Errors';

@Component({
  selector: 'app-e-error',
  templateUrl: './e-error.component.html',
  styleUrl: './e-error.component.scss',
})
export class EErrorComponent implements OnInit, OnDestroy {
  errorMessage: string = '';
  textConstants = TextConstants;

  private timeoutRef: any;

  constructor(private dialogRef: MatDialogRef<EErrorComponent>, @Inject(MAT_DIALOG_DATA) private data: IErrorUrlParams) {}

  ngOnInit() {
    const {
      error: { title },
    } = this.data;

    if (title) {
      this.errorMessage = title;
    }

    this.timeoutRef = setTimeout(() => {
      this.dialogRef.close();
    }, 5000);
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutRef);
  }
}
