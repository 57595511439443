import { Component, HostListener, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import * as TextConstants from '../../constants/text.constants';

@Component({
  selector: 'app-e-confirmation-dialog',
  templateUrl: './e-confirmation-dialog.component.html',
  styleUrl: './e-confirmation-dialog.component.scss',
})
export class EConfirmationDialogComponent {
  textConstants = TextConstants;
  @Input() dialogTitle: string = 'Dialog';
  @Input() confirmationMessage: string = 'Are you sure you want to delete?';
  @Input() confirmBtnMessage: string = 'Confirm';

  constructor(private dialogRef: MatDialogRef<EConfirmationDialogComponent>) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.dialogRef.close(true);
    } else if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmDialog(): void {
    this.dialogRef.close(true);
  }
}
