<div class="sub-section">
  <div class="section-holder">
    <div class="section-overview">
      <div class="image-holder">
        <!-- <img width="187.15px" height="100px" style="border-radius: 5px; object-fit: cover" [src]="videoThumbnailUrl" alt="" /> -->
        <div
          class="image"
          [style.background-image]="
            'linear-gradient(359.85deg, #BD268F -1.38%, rgba(184, 168, 216, 0) 50.07%), url(' +
            videoThumbnailUrl +
            ')'
          "
          [style.background-size]="'cover'"
        ></div>
      </div>
      <div class="info-container">
        <div id="title-section">{{ data.title }}</div>
        @if (data.subtitle) {
        <div id="subtitle-section">{{ data.subtitle }}</div>
        }
        <div>{{ data.duration }}</div>
      </div>
    </div>
    <div class="action-buttons">
      <app-e-button (click)="toggleDropdown()" btnClass="nested-content">
        <img
          [src]="isHovered ? hoverIconSrc : iconSrc"
          (mouseover)="isHovered = true"
          (mouseout)="isHovered = false"
          [ngClass]="{ rotate: true, down: isDropdownOpen }"
          alt="Dropdown Button"
          height="26"
          width="27"
        />
      </app-e-button>
    </div>
  </div>
</div>
@if (isDropdownOpen) {
<div class="section-parts-container">
  <div class="section-parts-holder">
    @for (part of sectionParts; track part; let i = $index) {
    <div class="part-holder" (click)="openSelectedContent(part.id)">
      <div class="title-holder">
        {{ i + 1 + '. ' + part.title }}
      </div>

      <div class="duration-holder">
        @if (part.type !== 'Adaptive') {
        <div class="duration-information">
          <img src="assets/images/time_icon.svg" alt="" />
          {{ part.duration }}
        </div>
        } @if (isEndUser == true) {
        <mat-progress-bar [value]="part.sectionProgress" />
        @if (part.sectionProgress == 100) {
        <img class="checkbox" src="assets/images/checkbox_full.svg" alt="Checkbox" />
        } @else {
        <img class="checkbox" src="assets/images/checkbox_empty.svg" alt="Checkbox" />
        } }
      </div>
    </div>
    }
  </div>
</div>

}
