@if (isFileInput) {
<div [ngClass]="[inputClass]">
  <label [for]="id" class="file-upload-label">Choose file</label>
  <input type="file" [id]="id" #fileInput class="file-upload-input" (change)="onInput($event)" />
  <span class="file-name">{{ fileName }}</span>
</div>
} @else { @if (label.length > 0) {
<div class="label">{{ label }}</div>
}
<input
  (input)="onInput($event)"
  (focus)="onFocus()"
  (blur)="onBlur()"
  [readonly]="isReadOnly"
  [maxLength]="maxLength"
  [ngStyle]="inputStyles"
  [ngClass]="[inputClass, invalid ? 'input-invalid' : '']"
  [placeholder]="displayPlaceholder ? placeholderContent : ''"
  [value]="value"
  [type]="isPassword ? 'password' : 'text'"
  id="input"
  #input
  (keyDown.enter)="inputOnEnter && onEnterPress()"
  [name]="label"
/>
}
