import { AfterViewInit, booleanAttribute, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as TextConstants from '../../constants/text.constants';

@Component({
  selector: 'app-e-text-area',
  templateUrl: './e-text-area.component.html',
  styleUrl: './e-text-area.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ETextAreaComponent),
      multi: true,
    },
  ],
})
export class ETextAreaComponent implements ControlValueAccessor, AfterViewInit {
  @Input() labelContent: string = '';
  @Input() showTooltip: boolean = false;
  @Input() placeholderContent: string = '';
  @Input() maxLength: number | null = null;
  @Input() inputClass: string = 'text-area-field';
  @Input() resizable: boolean = false;
  @Input() autoResizable: boolean = false;
  @Input({ transform: booleanAttribute }) invalid: boolean = false;
  @Output() valueChanged = new EventEmitter<string>();
  displayPlaceholder: boolean = true;
  private _value: string = '';
  textConstants = TextConstants;

  @ViewChild('textArea') textAreaRef!: ElementRef;

  ngAfterViewInit(): void {
    // settimeout is used to resize textarea after data load on page refresh.
    // Without it textArea.scrollHeight has the default height of the textarea durring the first call of the function.
    setTimeout(() => this.autoResize(this.textAreaRef.nativeElement), 500);
  }

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  onInput(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    this.value = textarea.value;
  }

  onFocus(): void {
    this.displayPlaceholder = false;
  }

  onBlur(): void {
    this.displayPlaceholder = !this.value;
    this.valueChanged.emit(this.value);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: string): void {
    if (value !== undefined) {
      this._value = value;
      this.displayPlaceholder = !value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  autoResize(textArea: HTMLTextAreaElement) {
    if (this.autoResizable) {
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight - 9 + 'px';
    }
  }
}
