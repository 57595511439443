import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, input } from '@angular/core';
import { BaseOption } from '../../submodules/admin/models/admin-team-lead/admin-team-lead-team-option.model';
import { QuestionsService } from '../../services/questions.service';
import { QuestionOrderRequestModel } from '../../submodules/admin/models/content-manager/questions/QuestionOrderRequestModel';
import { EConfirmationDialogComponent } from '../e-confirmation-dialog/e-confirmation-dialog.component';
import { first } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-e-tabs',
  templateUrl: './e-tabs.component.html',
  styleUrls: ['./e-tabs.component.scss'],
})
export class ETabsComponent implements OnChanges {
  @Output() selectedNameChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() dropClass: string = '';
  @Input() imageUrl: string = '';
  @Input() numberTab!: number;
  @Input() options!: BaseOption[];
  @Input() isDragable: boolean = true;
  @Input() isTimeStamp: boolean = false;
  @Input() lastSelectedId!: any;
  @Input() isDelete: boolean = false;
  @Input() hasDeleteConfirm: boolean = false;
  @Input() courseId!: string;
  @Input() isKnowledgeTest: boolean = false;
  @Output() removeQuestionType: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateOrder: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedTab: BaseOption | null = null; // Add this line
  startIndex = -1;
  allSameTimestamp!: any[];

  constructor(private questionsService: QuestionsService, private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lastSelectedId']) {
      this.lastSelectedId = changes['lastSelectedId'].currentValue;
    }

    if (changes['options']) {
      this.options = changes['options'].currentValue;

      // Check if the last selected tab exists in the updated options array
      const index = this.options.findIndex((option) => option.id === this.lastSelectedId?.id);
      if (index !== -1) {
        this.selectedTab = this.options[index];
      }
    }
  }

  handleSelectedName(selectedValue: any) {
    this.selectedTab = selectedValue;

    this.allSameTimestamp = [];

    if (this.selectedTab?.timestamp) {
      for (let i = 0; i < this.options.length; i++) {
        const element = this.options[i];

        if (this.selectedTab.timestamp === element.timestamp) {
          this.allSameTimestamp.push(element);
        }
      }
    }
    this.selectedNameChange.emit(selectedValue);
  }

  isTimestampInAllSameTimeStamps(timestamp: any): boolean {
    if (this.allSameTimestamp) {
      return this.allSameTimestamp.includes(timestamp);
    } else {
      return false;
    }
  }

  getIndex(item: BaseOption, arr: BaseOption[]): number {
    return arr.indexOf(item) + 1;
  }

  onRemoveSectionClick(item: BaseOption) {
    if (this.hasDeleteConfirm) {
      const dialogRef = this.dialog.open(EConfirmationDialogComponent);
      const instance = dialogRef.componentInstance;
      instance.dialogTitle = `Remove ${item.name}`;

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe((confirmed) => {
          if (confirmed) {
            this.removeSection(item);
          }
        });
    } else {
      this.removeSection(item);
    }
  }

  removeSection(item: BaseOption) {
    if (this.options.length > 0) {
      const index = this.options.indexOf(item);
      this.options.splice(index, 1);

      this.questionsService.removeVideoQuestion(item.id, this.courseId).subscribe(() => {
        this.removeQuestionType.emit();
      });
    }
  }

  dragStart(index: number) {
    this.startIndex = index;
  }

  dragOver(event: DragEvent) {
    event.preventDefault();
  }

  drop(index: number) {
    if (this.startIndex !== index) {
      this.moveItem(this.startIndex, index);
      this.startIndex = -1;

      const orderedIds = this.options.map((a) => a.id);
      const request: QuestionOrderRequestModel = {
        courseId: this.courseId,
        id: this.options[index].id,
        questionIds: orderedIds,
      };

      if (this.isKnowledgeTest) {
        this.questionsService.updateKnowledgeTestOrder(request).subscribe();
      } else {
        this.questionsService.updatePrecheckVideoOrder(request).subscribe();
      }
    }
  }

  moveItem(fromIndex: number, toIndex: number) {
    const item = this.options.splice(fromIndex, 1)[0];
    this.options.splice(toIndex, 0, item);
  }
}
