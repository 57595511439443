import { AfterViewInit, booleanAttribute, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as uuid from 'uuid';

@Component({
  selector: 'app-e-input',
  templateUrl: './e-input.component.html',
  styleUrl: './e-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EInputComponent),
      multi: true,
    },
  ],
})
export class EInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() isReadOnly: boolean = false;
  @Input() label: string = '';
  @Input() placeholderContent: string = '';
  @Input() inputStyles!: { [key: string]: any };
  @Input() inputClass: string = 'input-field';
  @Input() isFileInput: boolean = false;
  @Input({ transform: booleanAttribute }) invalid: boolean = false;
  @Input() inputOnEnter: boolean = false;
  @Input() isPassword: boolean = false;
  @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>();
  @Output() valueChanged: EventEmitter<string | File> = new EventEmitter<string | File>();
  @Output() enterPressed = new EventEmitter();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() maxLength: number = 50;
  fileName: string = 'No file selected';
  displayPlaceholder: boolean = true;
  id = uuid.v4();
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  private _value: string | File = '';

  get value(): string | File {
    return this._value;
  }

  ngAfterViewInit(): void {
    if (
      this.inputClass === 'module-input' ||
      this.inputClass === 'module-input-italic' ||
      this.inputClass === 'borderless-input' ||
      this.inputClass === 'borderless-input-italic'
    ) {
      this.value = this.placeholderContent;
      this.adjustInputWidth();
    }
  }

  set value(val: string | File) {
    this._value = val;
    this.onChange(val);
  }

  focusInputField(): void {
    if (this.input) {
      this.input.nativeElement.focus();
    }
  }

  onEnterPress(): void {
    if (this.input) {
      this.input.nativeElement.blur();
      this.enterPressed.emit();
    }
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length) {
      const file = input.files[0] || null;
      this.fileSelected.emit(file);
      if (file) {
        this.value = file;
        this.fileName = input.files[0].name;
      }
    } else {
      this.value = input.value;
    }

    if (
      this.inputClass === 'module-input' ||
      this.inputClass === 'module-input-italic' ||
      this.inputClass === 'borderless-input' ||
      this.inputClass === 'borderless-input-italic'
    ) {
      // Dynamically adjust the width of the input field
      this.adjustInputWidth();
    }
  }

  adjustInputWidth(): void {
    if (this.input) {
      const value = this._value.toString();
      const span = document.createElement('span');
      span.className = `${this.inputClass} tmp-span`;
      span.innerHTML = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      document.body.appendChild(span);
      const totalWidth = span.getBoundingClientRect().width;
      document.body.removeChild(span);
      this.input.nativeElement.style.width = `${totalWidth}px`;
    }
  }

  triggerFileUpload(): void {
    if (this.isFileInput && this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  onFocus: any = () => {
    this.displayPlaceholder = false;
  };

  onBlur(): void {
    this.displayPlaceholder = this.value === '' || this.value == null;

    this.valueChanged.emit(this.value);
    this.onTouched();
    this.input.nativeElement.blur();
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this._value = value;
      this.displayPlaceholder = !value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
