import { Component, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModulesListingModel } from '../../models/content-manager/modules/ModulesListingModel';
import { SectionsService } from '../../../../services/sections.service';
import { WelcomeFilesService } from '../../../../services/welcome-files.service';
import { ESectionsOverviewComponent } from '../e-sections-overview/e-sections-overview.component';

@Component({
  selector: 'app-course-overview',
  templateUrl: './course-overview.component.html',
  styleUrl: './course-overview.component.scss',
})
export class CourseOverviewComponent implements OnInit {
  @Input() data!: ModulesListingModel;
  @Input() sectionVideoThumbnailUrl: string = '';
  sections!: any[];

  isDropdownOpen: boolean = true;
  isLocked: boolean = true;
  isHovered: boolean = false;
  iconSrc: string = 'assets/images/select_icon_grey.svg';
  hoverIconSrc: string = 'assets/images/select_icon_grey_active.svg';

  @ViewChild('subSectionContainer', { read: ViewContainerRef }) sectionContainerRef!: ViewContainerRef;
  private componentRefs: ComponentRef<ESectionsOverviewComponent>[] = [];

  constructor(private sectionsService: SectionsService, private welcomeImageService: WelcomeFilesService) {}

  ngOnInit(): void {
    this.getSections();
  }

  getSections() {
    this.sectionsService.getAllSections(this.data.id).subscribe((sections) => {
      for (let index = 0; index < sections.length; index++) {
        const element = sections[index];

        const currentComponent = this.sectionContainerRef.createComponent(ESectionsOverviewComponent);

        currentComponent.instance.data = element;
        currentComponent.instance.videoThumbnailUrl = this.sectionVideoThumbnailUrl;
        this.componentRefs.push(currentComponent);
      }
    });
  }

  toggleDropdown() {
    this.isLocked = true;
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen == true) {
      this.getSections();
    } else {
      this.destroyComponents();
    }
  }

  destroyComponents() {
    this.componentRefs.forEach((componentRef) => {
      componentRef.destroy();
    });
    this.componentRefs = [];
  }
}
