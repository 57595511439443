<div class="dropdown-img" (click)="onDropdownClick()">
  <mat-form-field [ngClass]="dropClass">
    <mat-select
      [(value)]="selectedValue"
      (openedChange)="onDropdownOpenChange($event)"
      (selectionChange)="onDropdownChange($event.value)"
      [(ngModel)]="selectedValue"
      [panelClass]="panelClass"
      placeholder="{{ textLabel }}"
    >
      @for (arr of textArr; track arr) {
      <mat-option [value]="isName ? arr.name : arr.id">
        @if (plusSymbol + ' ') {
        {{ plusSymbol }}
        } @if (hasSubtext) {
        <div class="splited-email-container">
          <div class="splited-name">
            {{
              arr.name
                .split(' ')
                .slice(0, arr.name.split(' ').length - (arr.name.split(' ').slice(-1)[0].includes('@') ? 1 : 0))
                .join(' ')
            }}
          </div>
          <div class="splited-email">
            {{ arr.name.split(' ').slice(-1)[0].includes('@') ? arr.name.split(' ').slice(-1)[0] : '' }}
          </div>
        </div>

        } @else {
        {{ removeTags(arr.name) }}
        }
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <img (click)="onImageClick()" [class.rotated]="isImageRotated" [ngStyle]="imageStyles" [src]="imageUrl" />
</div>
