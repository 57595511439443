<div [ngClass]="[this.isValid ? inputClass :
     (!isValid && inputClass === 'upload' ? 'invalid-image-upload' : 'invalid-small-upload')]">
  <label class="file-upload-label" for="fileInput">
    <app-e-input #nestedInput
                 (fileSelected)="handleFileChange($event)"
                 [isFileInput]="true"
                 id="fileInput"
                 inputClass="file-image-wrapper" />
    <img alt="Upload Video" src="{{imageSrc}}" [class]="imageInputClass" />
  </label>
  <div class="text-section">
    <p class="upload-text">{{ uploadText }}</p>
    <p class="recommended">Recommended size:</p>
    <p class="size">{{ sizeInfoText }}</p>
    <p class="size">{{ sizeInfoText2 }}</p>
    <p class="size">{{ sizeInfoText3 }}</p>

  </div>
</div>
@if (!isValid) {
  <div class="error-message">{{ errorMessage }}</div>
}
