<div class="label">
  <div class="text">
    {{ labelContent }}
  </div>

  @if (showTooltip) {
  <div class="tooltip-container">
    <img class="info-icon" src="assets/images/info icon.svg" alt="Info" />
    <div class="tooltip-content">
      <!-- Replace this with the information you want to display -->
      {{ textConstants.INPUT_TOOLTIP_TEXT }}
    </div>
  </div>
  }
</div>

<textarea
  #textArea
  [attr.maxLength]="maxLength"
  (blur)="onBlur()"
  (focus)="onFocus()"
  (input)="autoResize(textArea); onInput($event)"
  [ngClass]="[inputClass, invalid ? 'input-invalid' : '']"
  [placeholder]="displayPlaceholder ? placeholderContent : ''"
  [style.resize]="resizable ? 'both' : 'none'"
  [value]="value"
></textarea>
