<div class="upper-section">
  <div class="title-holder">
    <div class="title-content">
      <div class="title">{{ data.title }}</div>
      @if (data.subtitle) {
      <div class="subtitle">{{ data.subtitle }}</div>
      }
    </div>
  </div>
  <div class="action-buttons">
    <app-e-button (click)="toggleDropdown()" btnClass="nested-content">
      <img
        [src]="isHovered ? hoverIconSrc : iconSrc"
        (mouseover)="isHovered = true"
        (mouseout)="isHovered = false"
        [ngClass]="{ rotate: true, down: isDropdownOpen }"
        alt="Dropdown Button"
        height="26"
        width="27"
      />
    </app-e-button>
  </div>
</div>
@if (isDropdownOpen) {

<ng-container #subSectionContainer />
}
