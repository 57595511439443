import { Injectable } from '@angular/core';
import * as RouteConstants from '../constants/route.constants';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private routeConstants = RouteConstants.COUNTRIES_URLS;

  constructor(private api: ApiService) {}

  getAll() {
    return this.api.get(this.routeConstants.GET_ALL);
  }

  getAllByRegionId(id: string) {
    let params = new HttpParams();
    params = params.set('regionId', id);

    return this.api.get(this.routeConstants.GET_ALL_BY_REGION_ID, params);
  }

  getAllByRegionName(id: string) {
    let params = new HttpParams();
    params = params.set('regionName', id);

    return this.api.get(this.routeConstants.GET_ALL_BY_REGION_NAME, params);
  }

  getById(id: string) {
    let params = new HttpParams();
    params = params.set('id', id);

    return this.api.get(this.routeConstants.GET_BY_ID, params);
  }
}
