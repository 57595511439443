import { HttpParams } from '@angular/common/http';
import * as RouteConstants from '../constants/route.constants';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ModulesListingModel } from '../submodules/admin/models/content-manager/modules/ModulesListingModel';
import { ModulesRequestModel } from '../submodules/admin/models/content-manager/modules/ModulesRequestModel';
import { ModulesEditSubtitleRequestModel } from '../submodules/admin/models/content-manager/modules/ModulesEditSubtitleRequestModel';
import { ModulesEditTitleRequestModel } from '../submodules/admin/models/content-manager/modules/ModulesEditTitleRequestModel';

@Injectable({
  providedIn: 'root',
})
export class ModulesService {
  private routeConstants = RouteConstants.MODULES_URLS;

  constructor(private api: ApiService) {}

  getAllModules(courseId: string) {
    const params = new HttpParams().set('courseId', courseId);

    return this.api.get(this.routeConstants.GET_ALL_MODULES, params);
  }

  getAllModuleIds(courseId: string) {
    const params = new HttpParams().set('courseId', courseId);

    return this.api.get(this.routeConstants.GET_ALL_MODULE_IDS, params);
  }

  getModulesById(id: string): Observable<ModulesListingModel> {
    const params = new HttpParams().set('id', id);

    return this.api.get(this.routeConstants.GET_CURRENT_MODULE, params);
  }

  createModule(request: ModulesRequestModel): Observable<any> {
    return this.api.post(this.routeConstants.CREATE_MODULE, request);
  }

  editTitle(request: ModulesEditTitleRequestModel): Observable<boolean> {
    return this.api.put(this.routeConstants.EDIT_MODULE_TITLE, request);
  }

  editSubtitle(request: ModulesEditSubtitleRequestModel): Observable<boolean> {
    return this.api.put(this.routeConstants.EDIT_MODULE_SUBTITLE, request);
  }

  deleteModule(id: string, courseId: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('id', id);
    params = params.set('courseId', courseId);

    return this.api.delete(this.routeConstants.DELETE_MODULE, params);
  }
}
