import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BaseOption } from '../../submodules/admin/models/admin-team-lead/admin-team-lead-team-option.model';

@Component({
  selector: 'app-e-dropdown',
  templateUrl: './e-dropdown.component.html',
  styleUrl: './e-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EDropdownComponent),
      multi: true,
    },
  ],
})
export class EDropdownComponent implements ControlValueAccessor {
  isImageRotated: boolean = false;
  @Input() imageStyles: { [key: string]: any } = {
    'margin-left': '83.5%',
    'cursor': 'pointer',
  };
  @Input() customClass!: string;
  @Input() textArr!: any[];
  @Input() textLabel!: string;
  @Input() plusSymbol!: string;
  @Input() dropClass: string = 'dropdown-wrapper';
  @Input() panelClass: string = 'panel-wrapper';
  @Input() imageUrl: string = 'assets/images/select_icon.svg';
  @Input() hasSubtext: boolean = false;
  @Input() isName: boolean = false;
  @Output() dropdownOpen = new EventEmitter<void>();
  @Output() selectedValueChanged = new EventEmitter<string>();
  @Output() dropdownChange = new EventEmitter<BaseOption>();
  @ViewChild(MatSelect) matSelect!: MatSelect;
  @Input() selectedValue!: string;

  onImageClick() {
    this.isImageRotated = !this.isImageRotated;
    this.matSelect.toggle();
  }

  onDropdownOpenChange(isOpen: boolean): void {
    this.dropdownOpen.emit();
    this.isImageRotated = isOpen;
  }

  onDropdownClick(): void {
    this.dropdownOpen.emit();

    const el = document.getElementsByClassName('panel-wrapper');

    el[0].classList.add('app-scroll');
  }

  removeTags(data: string): string {
    let sanitizedData = data.replace(/&#39;/g, "'");

    sanitizedData = sanitizedData.replace(/(<([^>]+)>)/gi, '');

    return sanitizedData;
  }

  // Called when a new value from the form model is written to the component
  onDropdownChange(value: string): void {
    let selectedOption = this.textArr.find((option) => option.id === value);
    if (this.isName) {
      selectedOption = this.textArr.find((option) => option.name === value);
    }

    if (selectedOption) {
      this.writeValue(value); // Use the value directly, which should be the id
      this.onChange(value);
      this.selectedValueChanged.emit(value);

      if (this.hasSubtext) {
        const splitedValue = selectedOption.name.split(' ');
        const name = splitedValue.slice(0, -1).join(' ');

        selectedOption.name = name;

        this.dropdownChange.emit(selectedOption);
      } else {
        this.dropdownChange.emit(selectedOption);
      }
    }
  }

  // Called when a new value from the form model is written to the component
  writeValue(value: any): void {
    if (value !== undefined) {
      this.selectedValue = value;
      // If the MatSelect view is initialized, update its value as well
      if (this.matSelect) {
        this.matSelect.value = value;
      }
    }
  }

  // Registers a callback function that should be called when the control's value changes in the UI
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Registers a callback function that should be called when the control is touched
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Function to call when the select value changes
  onChange = (value: any) => {};

  // Function to call when the select is touched
  onTouched = () => {};
}
